.button,
.buttonSecondary {
	height: 1.67vw;
	padding: 0 0.94vw;
	font-size: 0.73vw;
	line-height: 1.15vw;
	font-weight: 400;
	border-radius: 2px;
}
.buttonInactive {
	color: rgba(0, 0, 0, 0.25);
	background-color: rgba(245, 245, 245, 1);
	border: 1px solid rgba(217, 217, 217, 1);
	pointer-events: none;
}
.buttonActive {
	color: rgba(255, 255, 255, 1);
	background-color: rgba(33, 82, 148, 1);
	border: 1px solid rgba(33, 82, 148, 1);
}
.buttonActive:hover {
	cursor: pointer;
	color: rgba(33, 82, 148, 1);
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid rgba(33, 82, 148, 1);
}
.buttonActiveSecondary {
	color: rgba(0, 0, 0, 0.85);
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid rgba(217, 217, 217, 1);
	cursor: pointer;
}
.buttonActiveSecondary:hover {
	color: rgba(33, 82, 148, 1);
	border: 1px solid rgba(33, 82, 148, 1);
}
.buttonActiveSecondaryIcon .buttonActiveIcon,
.buttonInactiveIcon {
	display: flex;
	justify-content: center;
	align-items: center;
}
.buttonActiveSecondaryIcon svg,
.buttonActiveIcon svg,
.buttonInactiveIcon svg {
	margin-right: 10px;
}
.rightExtraButton {
	justify-self: end;
	align-self: center;
}
.buttonBarRight {
	display: flex;
	column-gap: 1.04vw;
	align-items: center;
	justify-self: end;
}
.buttonBarLeft {
	display: flex;
	column-gap: 1.04vw;
	align-items: center;
}
.buttonIcoPlus {
	padding: 0;
	border: none;
	background-color: transparent;
}
.buttonIcoPlus svg {
	color: rgba(33, 82, 148, 1);
	font-size: 1.67vw;
}
.buttonIcoPlus:hover {
	border: none;
	background-color: transparent;
}
