.topBar {
	display: grid;
	grid-template: 84px / 676px 1fr;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid rgba(240, 240, 240, 1);
	padding: 0 40px 0 40px;
}
.topBar label {
	display: flex;
	grid-column-gap: 5px;
	flex-direction: column;
	font-size: 14px;
	line-height: 14px;
	font-weight: 400;
	color: rgba(120, 120, 120, 0.85);
	padding: 19px 0;
}
.topBar input {
	border: none;
	outline: none;
	padding: 0;
	border-bottom: 1px solid rgba(38, 38, 38, 1);
	font-size: 24px;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.85);
}
.topBar .inputOnchange {
	border-bottom: none;
	line-height: 40px;
}
.buttonBox {
	justify-self: end;
	display: flex;
	align-items: center;
	gap: 32px;
}
.icoDel {
	font-size: 36px;
	color: rgba(0, 0, 0, 0.85);
	opacity: 20%;
	cursor: pointer;
}
.icoDel:hover {
	opacity: 1;
}
