.modalContentWrap label {
	display: flex;
	grid-column-gap: 5px;
	flex-direction: column;
	font-size: 14px;
	line-height: 14px;
	font-weight: 400;
	color: rgba(120, 120, 120, 0.85);
	padding: 19px 0;
}
.modalContentWrap input {
	border: none;
	outline: none;
	padding: 0;
	border-bottom: 1px solid rgba(38, 38, 38, 1);
	font-size: 24px;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.85);
	height: 32px;
}
.modalContentWrap .inputOnchange {
	border-bottom: none;
	line-height: 40px;
}
