.tableWrap {
	padding: 0 10px 0 40px;
}
.table {
	height: 100%;
	margin-top: 42px;
	padding-right: 22px;
	scrollbar-gutter: stable;
}
.tableHead {
	display: grid;
	grid-template: 1fr / 490px;
}
.tableRow {
	display: grid;
	grid-template: min-content min-content / 490px;
}
.headCell {
	padding: 8px 16px;
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.85);
	border-top: 1px solid rgba(240, 240, 240, 1);
	border-bottom: 1px dotted rgba(0, 0, 0, 1);
	border-right: 1px solid rgba(240, 240, 240, 1);
	border-left: 1px solid rgba(240, 240, 240, 1);
}
.cell {
	padding: 8px 16px;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.85);
	border-bottom: 1px solid rgba(240, 240, 240, 1);
	border-right: 1px solid rgba(240, 240, 240, 1);
	border-left: 1px solid rgba(240, 240, 240, 1);
}
