.mainWrapper {
	min-height: 100vh;
	display: grid;
	grid-template: 72px 1fr / 90px 1fr;
	grid-template-areas:
		'header  header'
		'sidebar content';
}
.mainHead {
	grid-area: header;
}
.mainContent {
	grid-area: content;
	background-color: rgb(245, 245, 245);
	padding: 18px;
}
.mainSide {
	grid-area: sidebar;
}
.errorContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 72px);
}
.errorCard {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 10px;
}
