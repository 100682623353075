.content {
	height: 100%;
	color: rgba(0, 0, 0, 0.85);
}
.tabsAside {
	width: 100%;
	height: 100%;
}
.tabBarAsideWrap + div > div {
	height: 100%;
}
.tabBarAsideWrap + div > div > div {
	height: 100%;
}
.tabBarAsideWrap {
	padding: 40px 40px 40px 40px;
	background-color: rgba(255, 255, 255, 1);
	border: 1px solid rgba(240, 240, 240, 1);
}
.tabBarAside {
	width: 370px;
	height: min-content;
	font-weight: 600;
	border-left: 1px solid rgba(240, 240, 240, 1);
}
.tabBarAsideItem {
	text-align: start;
	white-space: break-spaces;
	padding-left: 24px;
}
