.listItem {
	min-height: 24px;
	padding-left: 58px;
	padding-right: 17px;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
}

.asideLeft {
	padding: 12px 10px;
	background-color: rgba(255, 255, 255, 1);
	border-right: 1px solid rgba(237, 241, 247, 1);
}

.listWrapper {
	padding: 8px;
	min-height: calc(100vh - 60px);
	overflow: auto;
}

.listWrapper::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
.listWrapper::-webkit-scrollbar-track {
	background: transparent;
}
.listWrapper::-webkit-scrollbar-thumb {
	background-color: rgba(188, 188, 188, 1);
	border-radius: 39px;
}
.listWrapper::-webkit-scrollbar-track-piece:end {
	background: transparent;
}
