.asideRight {
	padding: 58px 114px 58px 128px;
	background-color: rgba(255, 255, 255, 1);
}
.dropdown {
	width: 300px;
}
.dropdownBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 12px;
}
.dropdownBar svg {
	font-size: 16px;
}
.dropdownTitle {
	font-size: 18px;
	font-weight: 700;
}
.searchWrap {
	margin-top: 13px;
	padding: 0 12px;
}

.searchWrap p {
	font-weight: 700;
	margin-bottom: 5px;
}

.searchCount {
	font-size: 12px;
	font-weight: 400;
	color: rgba(99, 134, 180, 1);
	padding-left: 12px;
}
.buttonWrap {
	padding: 12px 12px 0;
	border-top: 1px solid rgba(88, 122, 169, 1);
}
.buttonActive {
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 10px;
	height: 32px;
	padding: 0 18px;
	font-size: 14px;
	line-height: 22px;
	font-weight: 400;
	border-radius: 2px;
	color: rgba(255, 255, 255, 1);
	background-color: rgba(33, 82, 148, 1);
	border: 1px solid rgba(255, 255, 255, 1);
}
.buttonActive:hover {
	cursor: pointer;
	color: rgba(255, 255, 255, 1);
	background-color: rgba(33, 82, 148, 1);
	border: 1px solid rgba(88, 122, 169, 1);
}
.scrolledDrop ul {
	list-style: none;
	overflow-y: auto;
	max-height: 200px;
	margin-top: 12px !important;
	margin-bottom: 12px !important;
	padding: 0;
}
.scrolledDrop li {
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	padding: 6px 6px 10px 0;
}
.scrolledDrop li:hover {
	background-color: rgba(57, 101, 159, 1);
}
.scrolledDrop ul::-webkit-scrollbar {
	width: 8px;
	height: 113px;
}
.scrolledDrop ul::-webkit-scrollbar-track {
	background: transparent;
}
.scrolledDrop ul::-webkit-scrollbar-thumb {
	background-color: rgba(28, 71, 128, 1);
	border-radius: 39px;
}
.resultItem {
	padding: 0 12px;
}
.title {
	font-size: 14px;
	font-weight: 400;
	color: rgba(255, 255, 255, 1);
	line-height: 14px;
}
.titleWithoutType {
	font-size: 14px;
	font-weight: 400;
	color: rgba(255, 255, 255, 1);
}
.type {
	display: block;
	margin-top: 2px;
	padding-left: 12px;
	font-size: 12px;
	font-weight: 400;
	color: rgba(99, 134, 180, 1);
	line-height: 12px;
}
.element {
	margin-top: 10px;
	margin-bottom: 18px;
}
