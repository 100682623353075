.tabAside {
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 1);
	border-top: 1px solid rgba(240, 240, 240, 1);
	border-bottom: 1px solid rgba(240, 240, 240, 1);
	border-right: 1px solid rgba(240, 240, 240, 1);
}
.tabAsideBar {
	display: flex;
	justify-content: space-between;
	column-gap: 20px;
	align-items: center;
	padding: 40px 40px 0 40px;
}
