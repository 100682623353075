.scrolledContent {
	overflow-y: auto;
}
.scrolledContent::-webkit-scrollbar {
	width: 8px;
	height: 113px;
}
.scrolledContent::-webkit-scrollbar-track {
	background: transparent;
}
.scrolledContent::-webkit-scrollbar-thumb {
	background-color: rgba(188, 188, 188, 1);
	border-radius: 39px;
}
.scrolledContent::-webkit-scrollbar-track-piece:end {
	background: transparent;
}
