.projectName {
	display: flex;
	align-items: baseline;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	color: rgba(33, 82, 148, 1);
	cursor: pointer;
}
.projectName svg {
	margin-left: 10px;
	font-size: 9px;
}
.project {
	color: rgba(255, 255, 255, 0.85);
	padding: 5px 12px;
}
.selectedProject {
	color: rgba(255, 255, 255, 0.85);
	background-color: rgba(57, 101, 159, 1);
	padding: 5px 12px;
}
.buttonProject {
	background-color: rgba(33, 82, 148, 1);
	color: rgba(255, 255, 255, 0.85);
	border: 1px solid rgba(255, 255, 255, 1);
	margin: 12px 12px;
	cursor: pointer;
}
.buttonProject:hover {
	background-color: rgba(57, 101, 159, 1);
}
.buttonWrap {
	border-top: 1px solid rgba(217, 217, 217, 1);
}
.scrolledContent {
	overflow-y: auto;
	max-height: 226px;
}
.scrolledContent::-webkit-scrollbar {
	width: 8px;
	height: 113px;
}
.scrolledContent::-webkit-scrollbar-track {
	background: transparent;
}
.scrolledContent::-webkit-scrollbar-thumb {
	background-color: rgba(28, 71, 128, 1);
	border-radius: 39px;
}
.scrolledContent::-webkit-scrollbar-track-piece:end {
	background: transparent;
}
.projectModalFooter {
	text-align: start;
	background: transparent;
	margin-top: 12px;
	padding: 0 0 16px 0;
	border-top: none;
	border-radius: 0;
}
