.stageWrapper {
	grid-area: stage;
	overflow: hidden;
	position: relative;
}

.stage {
	overflow: hidden;
	background:
		linear-gradient(90deg, white calc(15px - 2px), transparent 1%) center / 15px 15px,
		linear-gradient(white calc(15px - 2px), transparent 1%) center / 15px 15px,
		#dedede;
}

.zoomButtons {
	position: absolute;
	right: 20px;

	display: flex;
	flex-direction: column;
	gap: 20px;
}

.zoomButtons div {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.zoomFit {
	font-size: 12px;
	font-weight: 400;
}
