.tools {
	grid-area: tools;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 22px;
}

.buttons {
	display: flex;
	align-items: center;
}

.button {
	background: #215294;
	border: 1px solid #38649f;
}

.button:hover {
	background: #38649f;
}

.active {
	background: #38649f;
}

.disabled {
	pointer-events: none;
	background: #bdc2ca63;
	border: 1px solid #bdc2ca63;
}
