.content.authDialog {
	width: 300px;
}

.innerContent {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.logo {
	margin-top: 15px;
}
.firstInput {
	margin-top: 30px;
}
.secondInput {
	margin-top: 10px;
}

.button {
	/*width: calc(unit * 25)*/
	width: 100%;
}

.container{
	height: 98vh;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow-y: hidden;
}

.loginForm {
	max-width: 300px;
	padding: 10px;
	border-radius: 10px;
	box-shadow: 0 0 35px -12px rgba(0, 0, 0, 0.2);
}

.logo {
	margin: 15px 0;
	text-align: center;
}

.forgot {
	float: right;
}

.ant-col-rtl .forgot{
	float: left;
}

.button {
	/*width: calc(unit * 25)*/
	width: 100%;
}