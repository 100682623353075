.listWrap {
	display: flex;
	gap: 4px;
	color: white;
}
.list {
	font-size: 14px;
	font-weight: 400;
	display: flex;
	gap: 4px;
}
.listBack {
	font-size: 14px;
	cursor: pointer;
}
.listBack.inactive {
	color: rgba(56, 100, 159, 0.85);
	cursor: not-allowed;
}
