.sider {
	grid-area: sider;
	border-right: 1px solid #EDF1F7;
	display: grid;
	grid-template-rows: 2fr 4fr 4fr;
}

.sider > div:nth-child(2) {
	border-top: 1px solid #EDF1F7;
}
