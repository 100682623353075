.tabAside {
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 1);
	border-top: 1px solid rgba(240, 240, 240, 1);
	border-bottom: 1px solid rgba(240, 240, 240, 1);
	border-right: 1px solid rgba(240, 240, 240, 1);
}
.tabAsideBar {
	display: flex;
	column-gap: 20px;
	align-items: center;
	padding: 40px 40px 0 40px;
}
.clear {
	font-size: 14px;
	font-weight: 400;
	color: rgba(33, 82, 148, 1);
	cursor: pointer;
}
.clear:hover {
	color: rgba(57, 101, 159, 1);
}
