.pageBlock {
	grid-area: pageBlock;
	display: flex;
	align-items: center;
}

.title {
	color: white;
	font-size: 14px;
	line-height: 22px;
	font-weight: 400;
	margin: 0 16px 0 24px;
}

.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	color: white;
}
