.tableWrap {
	padding: 0 10px 0 40px;
}
.table {
	height: 100%;
	margin-top: 42px;
	padding-right: 22px;
	scrollbar-gutter: stable;
}
.tableHead {
	display: grid;
	grid-template: 1fr / 40px 150px 1fr 1fr 1fr 1fr;
}
.tableRow {
	display: grid;
	grid-template: min-content min-content / 40px 150px 1fr 1fr 1fr 1fr;
}
.headCell {
	padding: 8px 16px;
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.85);
	border-top: 1px solid rgba(240, 240, 240, 1);
	border-bottom: 1px dotted rgba(0, 0, 0, 1);
	border-right: 1px solid rgba(240, 240, 240, 1);
	border-left: 1px solid rgba(240, 240, 240, 1);
}
.cell {
	padding: 8px 16px;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.85);
	border-bottom: 1px solid rgba(240, 240, 240, 1);
	border-right: 1px solid rgba(240, 240, 240, 1);
	border-left: 1px solid rgba(240, 240, 240, 1);
}
.cellIco {
	display: flex;
	justify-content: center;
	cursor: pointer;
}
.tableHeadAttributes,
.tableRowAttributes {
	display: grid;
	grid-template: 1fr / 262px 120px 120px;
}
.tableHeadAttributes .headCell {
	border-bottom: 1px solid rgba(240, 240, 240, 1);
}
.attributes {
	width: fit-content;
	margin: 16px 0;
	background-color: rgba(250, 250, 250, 1);
}
.cellAttributes {
	grid-column: span 6;
	padding: 0 40px;
	border-bottom: 1px solid rgba(240, 240, 240, 1);
	border-right: 1px solid rgba(240, 240, 240, 1);
	border-left: 1px solid rgba(240, 240, 240, 1);
}
.cellWarning {
	color: rgba(255, 0, 0, 0.85);
}
.scrolledHeight {
	height: calc(100vh - 224px);
}
.eventLogModal .table {
	height: 652px;
}
.eventLogModal .tableWrap,
.table {
	padding: 0;
	margin-top: 26px;
}
