.content {
	height: 100%;
	display: grid;
	grid-template: 84px 1fr / 1fr;
}
.card {
	margin-top: 16px;
	border: 1px solid rgba(240, 240, 240, 1);
	padding: 28px 40px;
}
.cardActive {
	background-color: rgba(255, 255, 255, 1);
}
.cardTitle {
	margin-bottom: 24px;
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.85);
}
.cardTitleInactive {
	opacity: 25%;
}
.labels {
	display: grid;
	grid-template: 1fr / 250px 250px;
}
.selectContainer {
	display: grid;
	grid-template: 1fr / 250px 250px auto;
	grid-row-gap: 10px;
}
.selectContainer svg {
	margin-left: 12px;
	color: rgba(0, 0, 0, 0.85);
	opacity: 20%;
	cursor: pointer;
}
.selectContainer svg:hover {
	opacity: 1;
}
.select {
	display: grid;
	flex-direction: column;
	gap: 14px;
	color: rgba(0, 0, 0, 0.85);
}
.labels label {
	margin-bottom: 15px;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	color: rgba(120, 120, 120, 0.85);
}
.labels .labelInactive {
	color: rgba(120, 120, 120, 0.85);
}
.selectInactive {
	pointer-events: none;
}
.addSelect {
	margin-top: 12px;
	font-size: 14px;
	font-weight: 400;
	color: rgba(33, 82, 148, 1);
}
.addSelect svg {
	margin-right: 9px;
}
