.container {
	background-color: white;
	padding: 20px;
	height: 100%;
	width: 100%;
}

.header {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
}

.table {
	margin-top: 10px;
}
