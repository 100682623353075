.header {
	background-color: #215294;
	width: 100%;
	display: grid;
	grid-template: 50px / minmax(330px, 1fr) 3fr 300px minmax(100px, 50px);
	grid-template-areas: 'pageBlock tools projects external';
	align-items: center;
	grid-area: header;
	padding: 0 16px;
}

.external {
	grid-area: external;
	justify-self: end;
}
